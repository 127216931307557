<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Contatti</li>
            </ul>
            <h2>Contattaci</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div id="map" class="d-none">
    <iframe src="https://maps.google.com/maps?q=Via%20Ada%20Negri,%2015,%2088900%20Crotone%20Italia&t=k&z=19&ie=UTF8&iwloc=&output=embed"></iframe>
</div>

<div class="contact-area ptb-100">
    <div class="container">
        <div class="row mb-5">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">Chi siamo</span>
                    <h2>CSU Academy
                    </h2>
                   <p>
                    CSU Academy è un centro di formazione professionale che si pone l’obiettivo di guidare i clienti a raggiungere i propri obiettivi professionali. CSU Academy è inoltre:
                    <b>Polo di studio ufficiale dell’Università eCampus; Polo di studio ufficiale Link Campus;Ente accreditato dalla Regione Calabria per la formazione e riqualificazione professionale a tutti i livelli;
                    Ente accreditato dalla Regione Calabria per l’erogazione di corsi di formazione sulla sicurezza dei lavoratori</b>
                    </p>
                    <p>
                        ENTI PUBBLICI<br>
                        <b>Vuoi partecipare a un concorso?</b><br>
                        Abbiamo tantissimi corsi con certificazioni che valgono 1 punto in graduatoria nelle ammissioni ai concorsi pubblici.
                    </p>
                    <p>
                        PROFILO PROFESSIONALE<br>
                        <b>Vuoi integrare crediti formativi?</b><br>
                        CSU Academy organizza sessioni d’esame per corsi per integrare crediti formativi, riservati ai liberi professionisti e a chi desidera lavorare negli enti pubblici.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">dalla formazione alla professione</span>
                    <h2>Qual'è il tuo obiettivo professionale?</h2>
                    <p>
                        CERTIFICATI<br>
                        <b>Vuoi diventare docente?</b><br>
                        I nostri corsi attivi ti consentono di aumentare il punteggio in graduatoria: scopri la nostra offerta di certificazioni informatiche, corsi di lingua, corsi di perfezionamento, corsi abilitanti per specifiche classi di concorso e altro ancora.
                    </p>
                    <p>
                        MONDO SCUOLA<br>
                        <b>Vuoi far parte del personale ATA?</b><br>
                        Nella nostra offerta formativa abbiamo corsi utili per incrementare il punteggio in graduatoria per il personale ATA: Dattilografia, IT Security, PEKIT e altre certificazioni digitali.
                    </p>
                    <p>
                        CORSI REGIONALI<br>
                        <b>Vuoi qualificarti professionalmente?</b><br>
                        Scopri tutti i nostri corsi accreditati dalla Regione in grado di qualificare e riqualificare professionalmente le persone
                    </p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">Informazioni</span>
                    <h2>Mettiti in contatto</h2>
                    <p>Ci piace ascoltare i lettori. Apprezziamo davvero che tu abbia dedicato del tempo per metterci in contatto.</p>
                    <ul>
                        <li>
                            <div class="icon">
                                <i class='bx bx-map'></i>
                            </div>
                            <h3>Dove siamo</h3>
                            <p>Sedi in tutta Italia</p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <h3>Contatti</h3>
                            <p>Mobile: <a href="tel:800728405">800 728 405</a></p>
                            <p>e-mail: <a href="mailto:infocsupoint@gmail.com">infocsupoint@gmail.com</a></p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-time-five'></i>
                            </div>
                            <h3>Siamo disponibili</h3>
                            <p>Lunedì - Venerdì: 09:00 - 18:00</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-form" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
                    <h2>Come possiamo aiutarti?</h2>
                    <p>L'indirizzo email non verrà pubblicato. i campi richiesti sono contrassegnati dal simbolo*</p>
                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name" placeholder="Nome e Cognome">
                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                        <div *ngIf="name.errors.required">Nome e Cognome richiesto.</div>
                                        <div *ngIf="name.errors.minlength">Numero minimo {{ name.errors.minlength.requiredLength }} di caratteri.</div>
                                        <div *ngIf="name.errors.maxlength">Numero massimo di 50 caratteri.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email" placeholder="la tua e-mail">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number" placeholder="cellulare">
                                    <div class="alert alert-danger" *ngIf="number.touched && !number.valid">un contatto telefonico è obbligatorio</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="5" class="form-control" placeholder="scrivi il tuo messaggio..."></textarea>
                                    <div class="alert alert-danger" *ngIf="message.touched && !name.valid">il messaggio è obbligatorio</div>
                                </div>
                            </div>
                            <h2 class="mt-3">Quale corso sei interessato?</h2>
                            <p>seleziona il corso interessato alla quale vuoi avere informazioni*</p>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <select name="sceltaCorso" ngModel id="sceltaCorso" #sceltaCorso="ngModel" style="display: block;
                                    width: 100%;
                                    border: none;
                                    color: var(--blackColor);
                                    background-color: #F3F6F9;
                                    height: 50px;
                                    padding-left: 15px;
                                    border-radius: 5px;
                                    font-size: var(--fontSize);
                                    font-weight: 600">
                                        <option value="{{elencoCorsiRow['corso']['titolo']}}" *ngFor="let elencoCorsiRow of elencoCorsi">{{elencoCorsiRow['corso']['titolo']}}</option>
                                      </select>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 mt-3">
                                <button type="submit" class="default-btn" [class.disabled]="!contactForm.valid">Invia Richiesta<span></span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
